var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contents-section"},[_c('div',{staticClass:"title-section"},[_c('h2',{staticClass:"d-flex align-center"},[_vm._v(" 그룹 매출/환불 "),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 ml-1",attrs:{"icon":"","loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.getSummary()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("새로고침")])])],1),_c('div',[_c('v-btn',{attrs:{"depressed":"","color":"success"},on:{"click":function($event){_vm.registDlg.type = 0, _vm.registDlg.show = true}}},[_vm._v(" 매출등록 ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":"error"},on:{"click":function($event){_vm.registDlg.type = 1, _vm.registDlg.show = true}}},[_vm._v(" 환불등록 ")])],1)]),_c('div',{staticClass:"main-section"},[_c('search-filter',{attrs:{"dateFilter":_vm.dateFilter,"radioFilters":_vm.radioFilters,"selectFilters":_vm.selectFilters,"search":_vm.search,"type":"server","searchPlaceHolder":"고객정보/영업자/상품명/번호"},on:{"updateSearch":_vm.updateSearch,"updateRadio":_vm.updateRadio,"updateSelect":_vm.updateSelect}}),_c('v-row',{staticClass:"mr-0 ml-0 mt-0 mb-0",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"d-flex align-center pl-0"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mt-4 mr-2 pr-1 pl-1 flex-shrink-0",attrs:{"label":"","outlined":"","elevation":"","link":"","color":"success","disabled":_vm.excelDownloadList === null || _vm.loading === true},on:{"click":function($event){return _vm.excelDownloadFunc()}}},tooltipOn),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("매출목록 다운로드")])]),_c('v-radio-group',{attrs:{"dense":"","row":"","value":_vm.options.itemsPerPage,"hide-details":""},on:{"change":function($event){_vm.options.itemsPerPage = $event}}},[_c('v-radio',{attrs:{"small":"","label":"50개씩 보기","value":50}}),_c('v-radio',{attrs:{"small":"","label":"100개씩 보기","value":100}}),_c('v-radio',{attrs:{"small":"","label":"500개씩 보기","value":500}}),_c('v-radio',{attrs:{"small":"","label":"1000개씩 보기","value":1000}}),_c('v-radio',{attrs:{"small":"","label":"3000개씩 보기","value":3000}})],1)],1),_c('div',{staticClass:"d-flex total"},[_c('p',[_c('v-chip',{staticClass:"mr-2",attrs:{"color":"success","small":"","outlined":""}},[_vm._v("매출")]),_vm._v(" "+_vm._s(_vm.comm.priceToString(_vm.totalAmount.sales))+" ")],1),_c('p',[_c('v-chip',{staticClass:"mr-2",attrs:{"color":"error","small":"","outlined":""}},[_vm._v("환불")]),_vm._v(" "+_vm._s(_vm.comm.priceToString(_vm.totalAmount.refund))+" ")],1),_c('p',[_c('v-chip',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":""}},[_vm._v("TOTAL")]),_vm._v(" "+_vm._s(_vm.comm.priceToString(_vm.totalAmount.total))+" ")],1)])],1),_c('data-table',{attrs:{"items":_vm.lists,"sorts":_vm.sorts,"headers":_vm.headers,"itemKey":"no","loading":_vm.loading},on:{"update:sorts":function($event){_vm.sorts=$event}},scopedSlots:_vm._u([{key:"no",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"line-break":"anywhere"}},[_vm._v(" "+_vm._s(item.no)+" ")])]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex",on:{"click":function($event){return _vm.contentsView(item.no)}}},'div',attrs,false),on),[(item.type === 1)?_c('v-chip',{staticClass:"pr-2 pl-2",attrs:{"label":"","color":"error","small":"","link":""}},[_vm._v("환불")]):(item.type === 0)?_c('v-chip',{staticClass:"pr-2 pl-2",attrs:{"label":"","color":"success","small":"","link":""},on:{"click":function($event){return _vm.contentsView(item.no)}}},[_vm._v("매출")]):_vm._e(),(item.method === 1)?_c('v-chip',{staticClass:"ml-1 pr-1 pl-1",attrs:{"label":"","color":"secondary","outlined":"","small":"","link":""}},[_vm._v("카드")]):(item.method === 0)?_c('v-chip',{staticClass:"ml-1 pr-1 pl-1",attrs:{"label":"","color":"accent","outlined":"","small":"","link":""}},[_vm._v("현금")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("열람")])])]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s((" " + (item.customerName) + " (" + (item.customerPhone.slice(-4)) + ") "))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.comm.makePhoneNumber(item.customerPhone)))])])]}},{key:"manager",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-left"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center cursor-pointer flex-wrap",on:{"click":function($event){return _vm.getHistories(
                  'sales.update',
                  'manager',
                  '영업담당자 변경이력',
                  item
                )}}},'div',attrs,false),on),[_c('v-chip',{staticClass:"pl-1 pr-1 mr-1",staticStyle:{"height":"auto","white-space":"pre-wrap"},attrs:{"link":"","small":"","label":"","outlined":""}},[_vm._v(_vm._s(_vm.comm.getGroupFullPath(_vm.groups, item.managerGroupNo, '그룹없음')))]),_vm._v(" "+_vm._s(item.managerName)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("영업담당자 변경이력 열람")])])],1)]}},{key:"groupNo",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-left"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.getHistories(
                  'sales.update',
                  'group',
                  '영업그룹 변경이력',
                  item
                )}}},'div',attrs,false),on),[_c('v-chip',{staticClass:"pl-1 pr-1",staticStyle:{"height":"auto","white-space":"pre-wrap"},attrs:{"link":"","small":"","label":"","outlined":""}},[_vm._v(_vm._s(_vm.comm.getGroupFullPath(_vm.groups, item.group, '그룹없음')))])],1)]}}],null,true)},[_c('span',[_vm._v("영업그룹 변경이력 열람")])])],1)]}},{key:"productNo",fn:function(ref){
                var item = ref.item;
return [(item.productType === 0)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-flex align-center"},'span',attrs,false),on),[_c('v-chip',{staticClass:"pl-1 pr-1 mr-1",attrs:{"x-small":"","color":"info","label":""}},[_vm._v("리딩")]),_vm._v(" "+_vm._s(item.productName)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(" 이용기간: "+_vm._s(item.productTerm)+" 개월"),_c('br'),_vm._v(" 무료이용기간: "+_vm._s(item.productFreeTerm)+" 개월 ")])]):_c('span',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"pl-1 pr-1 mr-1",attrs:{"x-small":"","color":"warning","label":""}},[_vm._v("코인")]),_vm._v(" "+_vm._s(item.productName)+" ")],1)]}},{key:"quantity",fn:function(ref){
                var item = ref.item;
return [(item.productType === 0)?_c('span',{staticClass:"gray--text"},[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(item.quantity))])]}},{key:"status",fn:function(ref){
                var item = ref.item;
return [_c('div',[(item.status === 0)?_c('v-chip',{staticClass:"pl-1 pr-1",attrs:{"small":"","label":""}},[_vm._v(" 미승인 ")]):(item.status === 2)?_c('v-chip',{staticClass:"pl-1 pr-1",attrs:{"small":"","label":"","outlined":"","color":"error"}},[_vm._v(" 가결제 ")]):(item.status === 1)?_c('v-chip',{staticClass:"pl-1 pr-1",attrs:{"small":"","label":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.time.makeLocalTime(item.date, 'date'))+" ")]):_vm._e()],1)]}},{key:"paymentsDiff",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item.type === 0)?_c('div',[(_vm.getPaymentsDiff(item) > 0)?_c('span',_vm._g(_vm._b({staticClass:"success--text"},'span',attrs,false),on),[_vm._v(" +"+_vm._s(_vm.comm.priceToString(_vm.getPaymentsDiff(item)))+" ")]):(_vm.getPaymentsDiff(item) < 0)?_c('span',_vm._g(_vm._b({staticClass:"error--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.comm.priceToString(_vm.getPaymentsDiff(item) * -1))+" ")]):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" 0 원 ")])]):(item.type === 1)?_c('span',{staticClass:"gray--text"},[_vm._v(" - ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" 판매가: "+_vm._s(_vm.comm.priceToString((item.productPrice * item.quantity) + item.discount))),_c('br'),_vm._v(" 결제총액: "+_vm._s(_vm.comm.priceToString(item.amount))),_c('br')])])]}},{key:"amount",fn:function(ref){
                var item = ref.item;
return [(item.amount === 0 || item.amount === null)?_c('span',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.comm.priceToString(item.amount))+" ")]):(item.amount > 0)?_c('b',{staticClass:"success--text",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.comm.priceToString(item.amount))+" ")]):(item.amount < 0)?_c('b',{staticClass:"error--text",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.comm.priceToString(item.amount))+" ")]):_vm._e()]}}])}),_c('div',{staticClass:"text-center pt-4 d-flex justify-center align-center"},[(Math.ceil(_vm.totalItems / _vm.options.itemsPerPage) > 1)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page === 1},on:{"click":function($event){_vm.options.page = 1}}},[_c('v-icon',[_vm._v("mdi-chevron-double-left")])],1):_vm._e(),(_vm.options.itemsPerPage > 0)?_c('v-pagination',{attrs:{"small":"","color":"primary","length":Math.ceil(_vm.totalItems / _vm.options.itemsPerPage),"totalVisible":"15","depressed":""},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}):_vm._e(),(Math.ceil(_vm.totalItems / _vm.options.itemsPerPage) > 1)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page === Math.ceil(_vm.totalItems / _vm.options.itemsPerPage)},on:{"click":function($event){_vm.options.page = Math.ceil(_vm.totalItems / _vm.options.itemsPerPage)}}},[_c('v-icon',[_vm._v("mdi-chevron-double-right")])],1):_vm._e()],1)],1),_c('dlg-sales-regist',{attrs:{"show":_vm.registDlg.show,"type":_vm.registDlg.type,"refresh":_vm.getSummary,"isLeader":true},on:{"update:show":function($event){return _vm.$set(_vm.registDlg, "show", $event)}}}),_c('dlg-history',{attrs:{"show":_vm.historyDlg.show,"data":_vm.historyDlg.data,"title":_vm.historyDlg.title},on:{"update:show":function($event){return _vm.$set(_vm.historyDlg, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }