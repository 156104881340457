<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-center">
        그룹 매출/환불
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              @click="getSummary()"
              :loading="loading"
              color="primary"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <div>
        <v-btn
          depressed
          color="success"
          @click="registDlg.type = 0, registDlg.show = true"
        >
          매출등록
        </v-btn>
        <v-btn
          class="ml-2"
          depressed
          color="error"
          @click="registDlg.type = 1, registDlg.show = true"
        >
          환불등록
        </v-btn>
      </div>
    </div>
    <div class="main-section">
      <search-filter
        :dateFilter="dateFilter"
        :radioFilters="radioFilters"
        :selectFilters="selectFilters"
        :search="search"
        type="server"
        searchPlaceHolder="고객정보/영업자/상품명/번호"
        @updateSearch="updateSearch"
        @updateRadio="updateRadio"
        @updateSelect="updateSelect"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col
          class="d-flex align-center pl-0"
        >
          <v-tooltip top color="black">
            <template #activator="{ on: tooltipOn }">
              <v-chip
                class="mt-4 mr-2 pr-1 pl-1 flex-shrink-0"
                label
                outlined
                elevation
                link
                color="success"
                v-on="tooltipOn"
                @click="excelDownloadFunc()"
                :disabled="excelDownloadList === null || loading === true"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-chip>
            </template>
            <span>매출목록 다운로드</span>
          </v-tooltip>

          <v-radio-group
            @change="options.itemsPerPage = $event"
            dense
            row
            :value="options.itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <div class="d-flex total">
          <p>
            <v-chip color="success" small class="mr-2" outlined>매출</v-chip>
            {{ comm.priceToString(totalAmount.sales) }}
          </p>
          <p>
            <v-chip color="error" small class="mr-2" outlined>환불</v-chip>
            {{ comm.priceToString(totalAmount.refund) }}
          </p>
          <p>
            <v-chip color="primary" small class="mr-2" outlined>TOTAL</v-chip>
            {{ comm.priceToString(totalAmount.total) }}
          </p>
        </div>
      </v-row>
      <data-table
        :items="lists"
        :sorts.sync="sorts"
        :headers="headers"
        itemKey="no"
        :loading="loading"
      >
        <template #no="{ item }">
          <div class="d-flex flex-wrap" style="line-break: anywhere">
            {{ item.no }}
          </div>
        </template>
        <template #type="{ item }">
          <v-tooltip top color="black">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-flex" @click="contentsView(item.no)">
                <v-chip label color="error" small link class="pr-2 pl-2"
                  v-if="item.type === 1"
                >환불</v-chip>
                <v-chip label color="success" small link class="pr-2 pl-2"
                  @click="contentsView(item.no)"
                  v-else-if="item.type === 0"
                >매출</v-chip>
                <v-chip label color="secondary" outlined small link
                  class="ml-1 pr-1 pl-1"
                  v-if="item.method === 1"
                >카드</v-chip>
                <v-chip label color="accent" outlined small link
                  class="ml-1 pr-1 pl-1"
                  v-else-if="item.method === 0"
                >현금</v-chip>
              </div>
            </template>
            <span>열람</span>
          </v-tooltip>
        </template>
        <template #customer="{ item }">
          <v-tooltip top color="black">
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{`
                  ${item.customerName}
                  (${item.customerPhone.slice(-4)})
                `}}
              </span>
            </template>
            <span>{{ comm.makePhoneNumber(item.customerPhone) }}</span>
          </v-tooltip>
        </template>
        <template #manager="{ item }">
          <div class="d-flex align-center justify-left">
            <v-tooltip top color="black">
              <template #activator="{ on, attrs }">
                <div
                  class="d-flex align-center cursor-pointer flex-wrap"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories(
                    'sales.update',
                    'manager',
                    '영업담당자 변경이력',
                    item,
                  )"
                >
                  <v-chip
                    link
                    style="height: auto; white-space: pre-wrap;"
                    class="pl-1 pr-1 mr-1"
                    small label outlined
                  >{{ comm.getGroupFullPath(groups, item.managerGroupNo, '그룹없음') }}</v-chip>
                  {{ item.managerName }}
                </div>
              </template>
              <span>영업담당자 변경이력 열람</span>
            </v-tooltip>
          </div>
        </template>
        <template #groupNo="{ item }">
          <div class="d-flex align-center justify-left">
            <v-tooltip top color="black">
              <template #activator="{ on, attrs }">
                <div
                  class="d-flex align-center cursor-pointer"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories(
                    'sales.update',
                    'group',
                    '영업그룹 변경이력',
                    item,
                  )"
                >
                  <v-chip
                    link
                    class="pl-1 pr-1"
                    style="height: auto; white-space: pre-wrap;"
                    small label outlined
                  >{{ comm.getGroupFullPath(groups, item.group, '그룹없음') }}</v-chip>
                </div>
              </template>
              <span>영업그룹 변경이력 열람</span>
            </v-tooltip>
          </div>
        </template>
        <template #productNo="{ item }">
          <v-tooltip top color="black" v-if="item.productType === 0">
            <template #activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs" class="d-flex align-center">
                <v-chip x-small class="pl-1 pr-1 mr-1" color="info" label>리딩</v-chip>
                {{ item.productName }}
              </span>
            </template>
            <span>
              이용기간: {{ item.productTerm }} 개월<br/>
              무료이용기간: {{ item.productFreeTerm }} 개월
            </span>
          </v-tooltip>
          <span class="d-flex align-center" v-else>
            <v-chip x-small class="pl-1 pr-1 mr-1" color="warning" label>코인</v-chip>
            {{ item.productName }}
          </span>
        </template>
        <template #quantity="{ item }">
          <span class="gray--text" v-if="item.productType === 0">
            -
          </span>
          <span v-else>{{ item.quantity }}</span>
        </template>
        <template #status="{ item }">
          <div>
            <v-chip
              v-if="item.status === 0"
              class="pl-1 pr-1"
              small label
            >
              미승인
            </v-chip>
            <v-chip
              v-else-if="item.status === 2"
              class="pl-1 pr-1"
              small label outlined
              color="error"
            >
              가결제
            </v-chip>
            <v-chip
              v-else-if="item.status === 1"
              class="pl-1 pr-1"
              small label outlined
            >
              {{ time.makeLocalTime(item.date, 'date') }}
            </v-chip>
          </div>
        </template>
        <template #paymentsDiff="{ item }">
          <v-tooltip top color="black">
            <template #activator="{ on, attrs }">
              <div v-if="item.type === 0">
                <span
                  v-if="getPaymentsDiff(item) > 0"
                  class="success--text"
                  v-on="on"
                  v-bind="attrs"
                >
                  +{{ comm.priceToString(getPaymentsDiff(item)) }}
                </span>
                <span
                  v-else-if="getPaymentsDiff(item) < 0"
                  class="error--text"
                  v-on="on"
                  v-bind="attrs"
                >
                  {{ comm.priceToString(getPaymentsDiff(item) * -1) }}
                </span>
                <span
                  v-on="on"
                  v-bind="attrs"
                  v-else
                >
                  0 원
                </span>
              </div>
              <span v-else-if="item.type === 1" class="gray--text">
                -
              </span>
            </template>
            <span>
              판매가: {{
                comm.priceToString((item.productPrice * item.quantity) + item.discount)
              }}<br/>
              결제총액: {{ comm.priceToString(item.amount) }}<br/>
            </span>
          </v-tooltip>

        </template>
        <template #amount="{ item }">
          <span v-if="item.amount === 0 || item.amount === null" style="text-align: right">
            {{ comm.priceToString(item.amount) }}
          </span>
          <b v-else-if="item.amount > 0" class="success--text" style="text-align: right">
            {{ comm.priceToString(item.amount) }}
          </b>
          <b v-else-if="item.amount < 0" class="error--text" style="text-align: right">
            {{ comm.priceToString(item.amount) }}
          </b>
        </template>
      </data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = 1"
          :disabled="options.page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="options.itemsPerPage > 0"
          small
          color="primary"
          v-model="options.page"
          :length="Math.ceil(totalItems / options.itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = Math.ceil(totalItems / options.itemsPerPage)"
          :disabled="options.page === Math.ceil(totalItems / options.itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <dlg-sales-regist
      :show.sync="registDlg.show"
      :type="registDlg.type"
      :refresh="getSummary"
      :isLeader="true"
    />
    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import time from '@/util/time';
import comm from '@/util/comm';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import DlgSalesRegist from '@/components/dialog/DlgSalesRegist.vue';
import DlgHistory from '@/components/dialog/DlgHistory.vue';
import DataTable from '@/components/datatable/DataTable.vue';
import debounce from 'debounce';

export default {
  name: 'LeaderSales',
  components: {
    SearchFilter,
    DlgSalesRegist,
    DlgHistory,
    DataTable,
  },
  data: () => ({
    comm,
    time,
    registDlg: {
      type: 0,
      show: false,
    },
    currentItem: null,
    groups: [],
    loading: true,
    dateFilter: {
      label: '매출/환불일',
      startDate: time.moment().add('-1', 'Y').format('YYYY-MM-DD'),
      startPicker: false,
      endDate: time.moment().format('YYYY-MM-DD'),
      endPicker: false,
    },
    lists: [],
    search: '',
    totalItems: 0,
    radioFilters: {
      type: {
        key: 'type',
        name: '종류',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '매출', value: 0 },
          { name: '환불', value: 1 },
        ],
      },
      productType: {
        key: 'productType',
        name: '상품',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '리딩', value: 0 },
          { name: '코인', value: 1 },
        ],
      },
      status: {
        key: 'status',
        name: '상태',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '미승인', value: 0 },
          { name: '승인', value: 1 },
          { name: '가결제', value: 2 },
        ],
      },
      method: {
        key: 'method',
        name: '결제',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '현금', value: 0 },
          { name: '카드', value: 1 },
        ],
      },
    },
    selectFilters: {
      groupNo: {
        key: 'groupNo',
        name: '그룹',
        value: '전체',
        data: [],
      },
    },
    options: {
      page: 1,
      itemsPerPage: 50,
      filters: [],
    },
    totalAmount: { refund: 0, sales: 0, total: 0 },
    sorts: {
      sortBy: ['no'],
      sortDesc: [true],
    },
    headers: [
      {
        text: '번호',
        value: 'no',
        align: 'center',
        width: 54,
        sortable: false,
      },
      {
        text: '종류', // 매출 or 환불
        value: 'type',
        align: 'center',
        sortable: false,
        width: 100,
      },
      {
        text: '상태(승인일)', // 상태
        value: 'status',
        align: 'left',
        sortable: false,
        width: 90,
      },
      {
        text: '고객정보',
        value: 'customer',
        align: 'left',
        sortable: false,
        width: 150,
      },
      {
        text: '상품',
        value: 'productNo',
        align: 'left',
        width: 200,
      },
      {
        text: '수량',
        value: 'quantity',
        align: 'center',
        width: 64,
      },
      {
        text: '결제차액',
        value: 'paymentsDiff',
        align: 'right',
        sortable: false,
        width: 120,
      },
      {
        text: '합계',
        value: 'amount',
        align: 'right',
      },
      {
        text: '영업담당자',
        value: 'manager',
        align: 'left',
        width: 260,
      },
      {
        text: '영업그룹',
        value: 'groupNo',
        align: 'left',
        width: 200,
      },
    ],
    historyDlg: {
      show: false,
      data: [],
      title: '',
      sub: null,
    },
    makeHistories: {
      status: (data, vue) => {
        const result = [];
        const thisType = vue.currentItem.type;
        const msgObj = {
          0: '미승인',
          1: '승인',
          2: '가결제',
          3: thisType === 1 ? '환불일 변경' : '매출일 변경',
        };
        data.forEach((r) => {
          if (r.after.status !== undefined || r.after.date !== undefined) {
            const msgKey = r.after.status !== undefined ? r.after.status : 3;
            let msg = msgObj[String(msgKey)];
            if (r.after.date !== undefined) {
              msg += `[${time.makeLocalDate(r.after.date, 'date')}]`;
            }
            result.push({ ...r, after: msg });
          }
        });
        return result;
      },
      manager: (data, staffs) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.manager !== undefined) {
            const { fullName } = staffs.find((row) => r.after.manager === row.id);
            result.push({ ...r, after: `${fullName}` });
          }
        });
        return result;
      },
      group: (data, vue) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.groupNo !== undefined) {
            result.push({ ...r, after: `${comm.getGroupFullPath(vue.groups, r.after.groupNo)}` });
          } else if (r.after.group !== undefined) {
            result.push({ ...r, after: `${comm.getGroupFullPath(vue.groups, r.after.group)}` });
          }
        });
        return result;
      },
    },
    excelDownloadList: [],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      route: 'router/route',
      saleDlg: 'dialog/sale',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
      sale: 'dialog/sale',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    /* 셀렉트필터 업데이트 */
    updateSelect(value, key) {
      this.selectFilters[key].value = value;
    },
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    getSummary() {
      this.loading = true;
      this.$socket.emit('sales.summary.get', {
        filters: this.makeFilters(),
      }, (res) => {
        if (res.result === 'success') {
          this.$socket.emit('sales.list.get', {
            ...this.options,
            ...this.sorts,
            filters: this.makeFilters(),
          }, (resp) => {
            if (resp.result === 'success') {
              this.totalItems = res.item.salesCount + res.item.refundCount;
              this.totalAmount = {
                refund: Number(res.item.refundAmount),
                sales: Number(res.item.salesAmount),
                total: Number(res.item.refundAmount) + Number(res.item.salesAmount),
              };
              this.lists = resp.items;
              if (this.options.page > Math.ceil(this.totalItems / this.options.itemsPerPage)) {
                this.options.page = 1;
              }
              this.loading = false;
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.loading = false;
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${res.code})`]);
          console.error(res);
          this.loading = false;
        }
      });
    },
    getDefaultData() {
      this.loading = true;
      const errFunc = (resp) => {
        this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
        console.log(resp);
        this.loading = false;
        this.lists = [];
      };
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (res) => {
        if (res.result === 'success') {
          this.groups = res.items.groups;
          if (this.userInfo.leaderGroups.length > 0) {
            const allChildrens = this.userInfo.leaderGroups
              .map((r) => comm.getGroupChildrens(res.items.groups, r.no));
            const childrens = [...new Set([].concat(...allChildrens))];
            const leaderGroups = comm.makeGroupList(res.items.groups, 'select')
              .reduce((acc, r) => {
                if (childrens.includes(r.value)) acc.push(r);
                return acc;
              }, [{ name: '전체', value: '전체' }]);
            this.selectFilters.groupNo.data = leaderGroups;
          } else {
            this.selectFilters.groupNo.data = [{ name: '전체', value: '전체' }];
          }
          this.getSummary();
        } else {
          errFunc(res);
        }
      });
    },
    updateOptions(type, value) {
      this.options[type] = [value];
    },
    contentsView(no) {
      this.progress(true);
      this.$socket.emit('sales.get', { no }, (resp) => {
        if (resp.result === 'success') {
          this.sale({
            show: true,
            item: resp.item,
            func: () => {
              this.getSummary();
            },
          });
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getPaymentsDiff(item) {
      const paymentsAmount = item.amount === null ? 0 : item.amount;
      return paymentsAmount - ((item.productPrice * item.quantity) + item.discount);
    },
    getHistories(action, columnName, title, item) {
      this.progress(true);
      this.currentItem = item;
      this.$socket.emit('log.get', {
        action,
        key: String(this.currentItem.no),
      }, (resp) => {
        if (resp.result === 'success') {
          if (columnName === 'manager') {
            const staffs = [];
            this.$socket.emit('users.staffs.list.get', {
              page: 1,
              itemsPerPage: 0,
              sortBy: [],
              sortDesc: [],
              filters: [],
            }, (response) => {
              if (response.result === 'success') {
                response.items.forEach((r) => {
                  const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                  const user = {
                    id: r.id,
                    fullName: `${groupName}${r.name}`,
                    name: r.name,
                    group: groupName,
                  };
                  staffs.push(user);
                });
                const filterData = this.makeHistories[columnName](resp.items, staffs);
                this.historyDlg.data = filterData;
                this.historyDlg.show = true;
                this.historyDlg.title = title;
                this.progress(false);
              } else {
                this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
                console.error(response);
                this.progress(false);
              }
            });
          } else {
            const filterData = this.makeHistories[columnName](resp.items, this);
            this.historyDlg.data = filterData;
            this.historyDlg.show = true;
            this.historyDlg.title = title;
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    excelDownloadFunc() {
      this.progress(true);
      this.excelDownloadList = null;
      this.$socket.emit('sales.list.get', {
        ...this.options,
        ...this.sorts,
        itemsPerPage: 0,
        filters: this.makeFilters(),
      }, (resp) => {
        if (resp.result === 'success') {
          if (resp.items.length <= 0) {
            this.alert(['error', '다운로드할 목록이 없습니다.']);
            this.excelDownloadList = [];
            this.progress(false);
          } else {
            const typeArr = ['매출', '환불'];
            const statusArr = ['미승인', '승인', '가결제'];
            const productTypeArr = ['리딩', '코인'];
            const list = resp.items.reduce((arr, r) => {
              const row = {};
              row['매출번호'] = r.no;
              row['종류'] = typeArr[r.type];
              row['상태'] = statusArr[r.status];
              row['승인일'] = r.status === 1 ? time.makeLocalTime(r.date, 'date') : '-';
              row['고객정보'] = `${r.customerName}(${r.customerPhone.slice(-4)})`;
              row['상품종류'] = productTypeArr[r.productType];
              row['상품'] = r.productName;
              row['수량'] = r.productType === 0 ? '-' : r.quantity;
              row['결제차액'] = r.type === 0 ? this.getPaymentsDiff(r) : '-';
              row['매출합계'] = r.amount;
              row['영업담당자'] = `${comm.getGroupFullPath(this.groups, r.managerGroupNo, '그룹없음 ')}${r.managerName}`;
              row['영업그룹'] = comm.getGroupFullPath(this.groups, r.group, '그룹없음 ');
              arr.push(row);
              return arr;
            }, []);
            this.excelDownloadList = list;
            comm.makeExcelFile(this.excelDownloadList, `매출목록[${time.moment().format('YYYY-MM-DD HH_mm_ss')}].xlsx`);
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
          this.excelDownloadList = [];
        }
      });
    },
    makeFilters() {
      const filters = [{
        filters: [
          {
            filters: [
              { condition: 'ge', column: 'date', value: this.dateFilter.startDate },
              {
                where: 'and',
                condition: 'le',
                column: 'date',
                value: `${this.dateFilter.endDate} 23:59:59`,
              },
            ],
          },
          {
            where: 'or',
            condition: 'none',
            column: 'date',
          },
        ],
      },
      {
        where: 'and',
        condition: this.radioFilters.type.value === '전체' ? 'inc' : 'eq',
        column: 'type',
        value: this.radioFilters.type.value === '전체' ? '' : this.radioFilters.type.value,
      },
      {
        where: 'and',
        condition: this.radioFilters.productType.value === '전체' ? 'inc' : 'eq',
        column: 'productType',
        value: this.radioFilters.productType.value === '전체' ? '' : this.radioFilters.productType.value,
      },
      {
        where: 'and',
        condition: this.radioFilters.status.value === '전체' ? 'inc' : 'eq',
        column: 'status',
        value: this.radioFilters.status.value === '전체' ? '' : this.radioFilters.status.value,
      },
      {
        where: 'and',
        condition: this.radioFilters.method.value === '전체' ? 'inc' : 'eq',
        column: 'method',
        value: this.radioFilters.method.value === '전체' ? '' : this.radioFilters.method.value,
      }];
      if (this.selectFilters.groupNo.value === '전체') {
        const groupFilters = {
          where: 'and',
          filters: [],
        };
        const allChildrens = this.userInfo.leaderGroups
          .map((r) => comm.getGroupChildrens(this.groups, r.no));
        const childrens = [...new Set([].concat(...allChildrens))];
        childrens.forEach((r, i) => {
          const obj = {
            condition: 'eq',
            column: 'groupNo',
            value: r,
          };
          if (i > 0) obj.where = 'or';
          groupFilters.filters.push(obj);
        });
        filters.push(groupFilters);
      } else {
        filters.push({
          where: 'and',
          condition: 'eq',
          column: 'groupNo',
          value: this.selectFilters.groupNo.value,
        });
      }
      filters.push({
        where: 'and',
        filters: [
          {
            condition: 'inc',
            column: 'customerName',
            value: this.search,
          },
          {
            where: 'or',
            condition: 'inc',
            column: 'managerName',
            value: this.search,
          },
          {
            where: 'or',
            condition: 'inc',
            column: 'customerPhone',
            value: this.search,
          },
          {
            where: 'or',
            condition: 'inc',
            column: 'productName',
            value: this.search,
          },
          {
            where: 'or',
            condition: 'inc',
            column: 'no',
            value: this.search,
          },
        ],
      });
      return filters;
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          if (val.leaderGroups.length <= 0) {
            this.$router.push('/manager-sales');
          } else {
            this.getSummary = debounce(this.getSummary, 300);
            this.getDefaultData();
          }
        }
      },
    },
    options: {
      deep: true,
      handler() {
        if (this.userInfo != null && this.totalItems >= 0) {
          this.getSummary();
        }
      },
    },
    dateFilter: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    radioFilters: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    selectFilters: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    search: {
      handler() {
        this.getSummary();
      },
    },
    sorts: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    saleDlg: {
      deep: true,
      handler(val) {
        if (val.show === false) {
          this.getSummary();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.total {
  justify-content: flex-end;
  padding-top: 30px;
  p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & + p {
      margin-left: 20px;
    }
  }
}
.date-picker-top {
  background-color: #fff;
  border-bottom:1px solid #f2f2f2;
  display: flex;
  align-items: center;
  padding:15px;
  .tit {
    margin:0;
    font-size: 1.1rem;
    font-weight: 500;
    color: #262626;
    display: flex;
    align-items: center;
    i {
      margin-left: 2px;
    }
  }
}
.date-picker-content {
  border-radius: 0;
  .total-span {
    font-size: .85rem;
    font-weight: 500;
  }
}
.options {
  font-size: .9rem;
  padding:0px 20px;
  min-height: 34px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.gray--text {
  color: #ccc;
}
.detail-view-text {
  font-size: .9rem;
  margin-top: 18px;
  color: #888;
  &.true--text {
    color: #262626;
  }
}
.confirm-picker-body {
  background-color: #fff;
  padding: 10px;
  padding-top:0px;
  .section {
    position: relative;
    padding: 15px;
    display: flex;
    align-items: center;
    color: #333;
    justify-content: space-between;
    & + .section, & + .section-wrap {
      border-top: 1px dashed #ccc;
    }
    .tit {
      font-size: .9rem;
      font-weight: 500;
      margin:0;
    }
    .sub {
      font-weight: 600;
      font-size: .9rem;
      display: flex;
      align-items: center;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.search-picker {
  max-width: 800px;
  .v-list {
    max-height: 300px;
    overflow: auto;
  }
}
</style>
